import React, { useState, useEffect } from "react";
import Button from "./Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
library.add(...[faChevronUp]);

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (!visible && window.scrollY > window.innerHeight) {
      setVisible(true);
    } else if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return visible ? (
    <Button
      ariaLabel="Voltar para cima"
      style={{
        position: "fixed",
        right: "1rem",
        bottom: "1rem",
        padding: "0.5rem 1rem",
        borderColor: "white",
      }}
      onClick={() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
      type="primary-bg"
      content={<FontAwesomeIcon icon={"chevron-up"} />}
    />
  ) : (
    <></>
  );
};

export default BackToTop;
