import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";


 

const Image = ({ name = "", alt = "", className="", fixed=false }) => {
  const dt = useStaticQuery(
        graphql`
          query allTheImagesQueryHeader {
            allImageSharp {
              edges {
                node {
                 
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
            }
          }
        `
      );
  const findLogo = () =>
    dt.allImageSharp.edges.find((edge) => edge.node.fluid.originalName === name)
      .node.fluid;

  return (
    
    <Img alt={alt} className={className} fluid={findLogo(name)} />
  );
};

export default Image;
