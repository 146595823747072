import React, { useState, useEffect } from "react";
import config from "../../config";
import { StaticQuery, graphql } from "gatsby";
import BookAppointment from "./BookAppointment";
import Link from "./Link";
import Logo from "./Logo";

const Header = ({
  frontPage = true,
  selected = "",
  setVisible = () => {},
  ...props
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [visibilityClass, setVisibilityClass] = useState(
    frontPage || !selected ? "" : "navbar-shrink"
  );
  
  const toggleMenu = (value) => {
    setOpenMenu(value);
  };
 
  const handleScroll = function () {
    if (window.pageYOffset > 100 || (!frontPage && selected)) {
      document.querySelectorAll(".tl-wrapper")[0].style.transform = "";
      setVisibilityClass("navbar-shrink");
      setVisible(true);
    } else {
      setVisibilityClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          wp {
            generalSettings {
              description
              title
            }
          }
          wpMenu(name: { eq: "Main Menu" }) {
            name
            menuItems {
              nodes {
                label
                title
                description
                url
              }
            }
          }
        }
      `}
      render={(data) => (
        <nav
          style={{ position: "fixed" }}
          className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
          id="mainNav"
        >
          <div className="container">
            <Logo
              alt={config.description}
              className={"logo"}
              type={visibilityClass ? "color" : "normal"}
            />
            {(frontPage || selected) && (
              <>
                <button
                  onClick={(_) => toggleMenu(!openMenu)}
                  className={`navbar-toggler navbar-toggler-right ${
                    openMenu ? "" : "collapsed"
                  }`}
                  type="button"
                  aria-controls="navbarResponsive"
                  aria-expanded={openMenu}
                  aria-label="Toggle navigation"
                >
                  <div className={`menuToggle ${openMenu ? "active" : ""}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    openMenu ? "show" : ""
                  }`}
                  id="navbarResponsive"
                >
                  <ul className="navbar-nav ml-auto">
                    {(frontPage || selected) &&
                      data.wpMenu.menuItems.nodes
                        .map(
                          (item, i) =>
                            item.url && (
                              <li
                                title={item.title}
                                key={`menu-${i}`}
                                className={`nav-item`}
                              >
                                <Link
                                  direction={i % 2 === 0 ? "left" : "right"}
                                  to={`/${item.url.replace("#", "")}`}
                                  className={`nav-link ${
                                    selected === item.url.replace("#", "")
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {item.label}
                                </Link>
                              </li>
                            )
                        )
                        .filter((e) => e)}
                    <li className={`nav-item`}>
                      <BookAppointment title={config.cta} type={"fgBg"} />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      )}
    />
  );
};

export default Header;
