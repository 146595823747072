import React from "react";
import { faAtom, faHands, faBrain } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";


library.add(faAtom, faHands, faBrain);

const { description } = require("./config");

const duration = {
  duration: 60,
  firstduration: 60
};
const timetable = [
  
  {
    day: "terça-feira",
    starttime: "10:00",
    endtime: "19:00",
    ...duration,
  },
  {
    day: "quarta-feira",
    starttime: "10:00",
    endtime: "19:00",
    ...duration,
  },
  {
    day: "quinta-feira",
    starttime: "10:00",
    endtime: "19:00",
    ...duration,
  },
  {
    day: "sexta-feira",
    starttime: "10:00",
    endtime: "19:00",
    ...duration,
  },
  {
    day: "sábado",
    starttime: "10:00",
    endtime: "19:00",
    ...duration,
  },
 
];
const data = {
  bookAppointment: {
    title: "Agenda a tua sessão comigo",
    timetable,
    subtitle: "",
    bookFor: "Ana Emília",
    holidays: [],
    notificationsemail: "ola@anaemilia.pt",
    form: "",
  },

  sections: [
    {
      component: "Hero",
    },
    {
      component: "Services",
    },
    {
      component: "About",
    },
    {
      component: "Reviews",
    },
    {
      component: "Cta"
    },

    {
      name: "Horário",
      component: "Timetable",
      description: "Quais os serviços disponíveis?",

      content: {
        bg: false,
        cta: "Marcar Sessão",
        title: "Horário de atendimento",

        timetable,
        description: (
          <>
            <h3 style={{ fontWeight: "bold" }}>
              Consultas online e/ou presencial
            </h3>
            <small className="small-text text-color" style={{ color: "black" }}>
              (Poderá ser ajustado de acordo com as tuas necessidades,fala
              comigo e encontraremos a melhor solução)
            </small>
          </>
        ),
      },
    },
    {
      component: "Blog",
    },
    {
      name: "Contactos",
      component: "ContactUs",
      content: {
        title: "Como poderei ser contactado?",
        formLabel: "Fala Comigo",
        addressLabel: "Onde me encontrar?",
      },
    },
  ],
};

export default data;