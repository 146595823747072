let activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development"
let productionP = true || activeEnv === "production";


module.exports = {
  siteTitle: "", // <title>
  description: "",
  logo: "src/assets/img/logo.png",
  manifestName: productionP ? "" : "",
  manifestShortName: "", // max 12 characters
  manifestStartUrl: productionP ? "" : "",
  manifestBackgroundColor: "#afc4c0",
  manifestThemeColor: "#afc4c0",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/icon.png",
  pathPrefix: "/",
  wpUrl: "https://admin.anaemilia.pt/adm",
  url: productionP ? "https://www.anaemilia.pt/" : "http://127.0.0.1:8888",
  keywords: "keywords",

  cta: "Marcar Sessão",
  emailSignature: "Ana Emilia",
  emailAddr: "ola@anaemilia.pt",
  tel: "",
};
