import React, { useState } from "react";
import Button from "../Button";
import "./BookAppointment.scss";
import Modal from "react-bootstrap/Modal";
import data from "../../../data";
import BookAppointment from "./BookAppointment";

const BookAppointmentIndex = ({ title = "Marcar consulta", type, classes = {} }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Button
        onClick={() => setClicked(!clicked)}
        className={classes.button}
        content={<span>{title}</span>}
        type={type}
        data-toggle="modal"
        data-target="#bookAppointment"
      />
      {clicked && (
        <Modal
          show={clicked}
          onHide={() => setClicked(false)}
          
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{data.bookAppointment.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookAppointment {...data.bookAppointment}/>
          </Modal.Body>
  
        </Modal>
      )}
    </>
  );
};

export default BookAppointmentIndex;
