import React from "react";
import config from "../../config";

import Image from "./Image";
import Link from "./Link";

const Logo = ({ type = "normal" }) => {
  
  return (
    <Link className="navbar-brand" direction="down"  to={`/`}>
      <Image
        alt={config.description}
        className={"logo"}
        name={type === "color" ? "logo2.png" : "logo.png"}
      />
    </Link>
  );
};

export default Logo;
