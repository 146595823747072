import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";
import { GlobalState } from "./InfiniteScroll/Global";
import BackToTop from "./BackToTop";

import config from "../../config";
import "../assets/sass/grayscale.scss";
//require("typeface-source-sans-pro");
require("typeface-montserrat");


const Layout = ({
  children,
  seoComp,
  seo = [],
  title,
  wrapperClass,
  description = config.description,
  ...props
}) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.cookie = `from=${document.referrer}; path=/`;
    }
  }, []);
  return (
    <GlobalState>
      <StaticQuery
        query={graphql`
          query MetaQuery {
            wp {
              generalSettings {
                title
                description
                language
              }
            }
          }
        `}
        render={(data) => (
          <>
            {seoComp || (
              <Helmet
                title={data.wp.generalSettings.title}
                meta={[
                  ...[
                    {
                      name: "description",
                      content: data.wp.generalSettings.description,
                    },
                    { name: "keywords", content: config.keywords },
                  ],
                  ...seo,
                ]}
              >
                <html lang={data.wp.generalSettings.language} />
              </Helmet>
            )}
            <h1
              style={{
                color: "transparent",
                display: "inline",
                position: "fixed",
                zIndex: "-1000",
                width: 0,
              }}
            >
              {data.wp.generalSettings.description} -{config.keywords}
            </h1>
            <div className={`page-top ${wrapperClass}`}>{children}</div>
            <CookieConsent
              location="bottom"
              buttonText="Aceito"
              className="cookieConsent"
              buttonClasses="button-em fg btn"
              disableButtonStyles={true}
              flipButtons={true}
              cookieName="cookie-concent"
            >
              Usamos cookies para lhe dar a melhor experiência de utilização. Ao
              continuar a utilizar o nosso site, assumimos que concorda com os
              termos :)
              <a
                style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
                rel="noreferrer"
                href="./privacidade/"
                target="_blank"
              >
                {" "}
                Politica Privacidade{" "}
              </a>
            </CookieConsent>
            <BackToTop />
          </>
        )}
      />
    </GlobalState>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
